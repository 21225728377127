import React from 'react';
import PageContainer from 'src/components/common/PageContainer';
import Header from '../common/Header';
import styled from '@emotion/styled';
import { useMediaQuery } from 'react-responsive';

function Guest() {
  const isMobile = useMediaQuery({ minWidth: 280 });
  const { pathname } = window.location;
  return (
    <PageContainer background="#222222">
      <Header title="NOTICE" />
      <ImageWrapper>
        <img
          src={`/images/guest/${isMobile ? 280 : 375}_${
            pathname.split('/')[1]
          }.png`}
          style={{ width: '100%', height: '100%' }}
        />
      </ImageWrapper>
    </PageContainer>
  );
}

export default Guest;

const ImageWrapper = styled.div``;
