import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

type Props = {
  title: string;
  fontSize?: string;
  color?: string;
  background?: string;
};

function Header({ title, fontSize, color, background }: Props) {
  return (
    <Layout background={background}>
      <Title fontSize={fontSize} color={color}>
        {title}
      </Title>
      <Link
        to="altava://landing?action=close"
        style={{ textDecoration: 'none' }}
      >
        <img src="/icons/icon_closeline.svg" />
      </Link>
    </Layout>
  );
}

export default Header;

const Layout = styled.div<{ background: string }>`
  height: 50px;
  background: ${({ background }) => (background ? `${background}` : '#fff')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  &::before {
    content: '';
    flex: 0 0 24px;
  }
`;

const Title = styled.p<{ fontSize: string; color: string }>`
  display: flex;
  color: ${({ color }) => (color ? `${color}` : '#212121')};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}` : '1.125rem')};
  font-family: 'Pretendard';
  font-weight: 700;
  margin: 0;
`;
