import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router';
import Layout from './layout/Layout';
import Guest from './components/notice/Guest';
import AdaOnline from './components/notice/AdaOnline';

const Loader = Component => props => {
  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

// 공지
const NoticeDetail = Loader(
  lazy(() => import('src/components/notice/NoticeDetail'))
);
const Issue = Loader(lazy(() => import('src/components/notice/Issue')));
const CNNotice = Loader(lazy(() => import('src/components/notice/CNNotice')));

// 약관
const Terms = Loader(lazy(() => import('src/components/gamebase/Terms')));
const Refund = Loader(lazy(() => import('src/components/gamebase/Refund')));
const Privacy = Loader(lazy(() => import('src/components/gamebase/Privacy')));
const RouletteProbability = Loader(
  lazy(() => import('src/components/gamebase/RouletteProbability'))
);

// 브랜드위크
const Sunnei = Loader(lazy(() => import('src/components/brandweek/Sunnei')));
const Secondskin = Loader(
  lazy(() => import('src/components/brandweek/Secondskin'))
);
const GentleMonster = Loader(
  lazy(() => import('src/components/brandweek/GentleMonster'))
);
const Armani = Loader(lazy(() => import('src/components/brandweek/Armani')));
const Rabanne = Loader(lazy(() => import('src/components/brandweek/Rabanne')));

// 컨셉이벤트
const Fall = Loader(lazy(() => import('src/components/concept/Fall')));
const Classic = Loader(lazy(() => import('src/components/concept/Classic')));
const PickYourColor = Loader(
  lazy(() => import('src/components/concept/PickYourColor'))
);
const KnitSet = Loader(lazy(() => import('src/components/concept/KnitSet')));
const Halloween = Loader(
  lazy(() => import('src/components/concept/Halloween'))
);

// 이벤트
const Showroom = Loader(lazy(() => import('src/components/event/Showroom')));

// 고객센터
const HelpCenter = Loader(
  lazy(() => import('src/components/help-center/HelpCenter'))
);

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [{ index: true, element: <NoticeDetail /> }],
  },
  {
    path: '/cn-notice',
    element: <Layout />,
    children: [{ index: true, element: <CNNotice /> }],
  },
  {
    path: 'terms',
    element: <Layout />,
    children: [
      { path: 'kr', element: <Terms /> },
      { path: 'us', element: <Terms /> },
      { path: 'cn', element: <Terms /> },
    ],
  },
  {
    path: 'refund',
    element: <Layout />,
    children: [
      { path: 'kr', element: <Refund /> },
      { path: 'us', element: <Refund /> },
      { path: 'cn', element: <Refund /> },
    ],
  },
  {
    path: 'privacy',
    element: <Layout />,
    children: [
      { path: 'kr', element: <Privacy /> },
      { path: 'us', element: <Privacy /> },
      { path: 'cn', element: <Privacy /> },
    ],
  },
  {
    path: 'roulette-probability',
    element: <Layout />,
    children: [{ index: true, element: <RouletteProbability /> }],
  },
  {
    path: 'notice',
    element: <Layout />,
    children: [{ path: 'adaonline', element: <AdaOnline /> }],
  },
  {
    path: 'us',
    element: <Layout />,
    children: [
      {
        path: 'notices',
        element: <Layout />,
        children: [
          { index: true, element: <NoticeDetail /> },
          {
            path: 'guest',
            element: <Guest />,
          },
        ],
      },
      {
        path: 'issue',
        element: <Layout />,
        children: [{ index: true, element: <Issue /> }],
      },
      {
        path: 'event',
        element: <Layout />,
        children: [{ path: 'showroom', element: <Showroom /> }],
      },
      {
        path: 'brandweek',
        element: <Layout />,
        children: [
          { path: 'sunnei', element: <Sunnei /> },
          {
            path: 'secondskin',
            element: <Secondskin />,
          },
          {
            path: 'gentle-monster',
            element: <GentleMonster />,
          },
          {
            path: 'armani',
            element: <Armani />,
          },
          {
            path: 'rabanne',
            element: <Rabanne />,
          },
        ],
      },
      {
        path: 'concept',
        element: <Layout />,
        children: [
          { path: 'fall', element: <Fall /> },
          { path: 'classic', element: <Classic /> },
          { path: 'pickyourcolor', element: <PickYourColor /> },
          { path: 'knit-set', element: <KnitSet /> },
          { path: 'halloween', element: <Halloween /> },
        ],
      },
    ],
  },
  {
    path: 'kr',
    element: <Layout />,
    children: [
      {
        path: 'notices',
        element: <Layout />,
        children: [
          { index: true, element: <NoticeDetail /> },
          {
            path: 'guest',
            element: <Guest />,
          },
        ],
      },
      {
        path: 'issue',
        element: <Layout />,
        children: [{ index: true, element: <Issue /> }],
      },
      {
        path: 'event',
        element: <Layout />,
        children: [{ path: 'showroom', element: <Showroom /> }],
      },
      {
        path: 'brandweek',
        element: <Layout />,
        children: [
          { path: 'sunnei', element: <Sunnei /> },
          {
            path: 'secondskin',
            element: <Secondskin />,
          },
          {
            path: 'gentle-monster',
            element: <GentleMonster />,
          },
          {
            path: 'armani',
            element: <Armani />,
          },
          {
            path: 'rabanne',
            element: <Rabanne />,
          },
        ],
      },
      {
        path: 'concept',
        element: <Layout />,
        children: [
          { path: 'fall', element: <Fall /> },
          { path: 'classic', element: <Classic /> },
          { path: 'pickyourcolor', element: <PickYourColor /> },
          { path: 'knit-set', element: <KnitSet /> },
          { path: 'halloween', element: <Halloween /> },
        ],
      },
    ],
  },
  {
    path: 'cn',
    element: <Layout />,
    children: [
      {
        path: 'help-center',
        element: <Layout />,
        children: [{ index: true, element: <HelpCenter /> }],
      },
      {
        path: 'notices',
        element: <Layout />,
        children: [
          { index: true, element: <NoticeDetail /> },
          {
            path: 'guest',
            element: <Guest />,
          },
        ],
      },
      {
        path: 'issue',
        element: <Layout />,
        children: [{ index: true, element: <Issue /> }],
      },
      {
        path: 'event',
        element: <Layout />,
        children: [{ path: 'showroom', element: <Showroom /> }],
      },
      {
        path: 'brandweek',
        element: <Layout />,
        children: [
          { path: 'sunnei', element: <Sunnei /> },
          {
            path: 'secondskin',
            element: <Secondskin />,
          },
          {
            path: 'gentle-monster',
            element: <GentleMonster />,
          },
          {
            path: 'armani',
            element: <Armani />,
          },
          {
            path: 'rabanne',
            element: <Rabanne />,
          },
        ],
      },
      {
        path: 'concept',
        element: <Layout />,
        children: [
          { path: 'fall', element: <Fall /> },
          { path: 'classic', element: <Classic /> },
          { path: 'pickyourcolor', element: <PickYourColor /> },
          { path: 'knit-set', element: <KnitSet /> },
          { path: 'halloween', element: <Halloween /> },
        ],
      },
    ],
  },
];

export default routes;
