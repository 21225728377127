import { useLocation, useRoutes } from 'react-router-dom';
import routes from './router';
import i18n from 'src/locales/i18n';
import { useEffect } from 'react';
import { locale } from 'src/utils/locale';

function App() {
  const content = useRoutes(routes);

  const location = useLocation();

  useEffect(() => {
    const country = location.pathname.split('/')[1];
    locale.some(item => {
      if (item.country === country) i18n.changeLanguage(item.language);
    });
  }, []);

  return <>{content}</>;
}

export default App;
