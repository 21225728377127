import { Stack } from '@mui/material';
import PageContainer from '../common/PageContainer';

function AdaOnline() {
  return (
    <PageContainer>
      <Stack sx={{ width: '100%', height: '100vh' }}>
        <iframe
          src="/notice/adaonline.html"
          title="update"
          width="100%"
          height="100%"
          style={{ border: 'none' }}
        />
      </Stack>
    </PageContainer>
  );
}

export default AdaOnline;
