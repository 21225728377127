export const locale = [
  {
    country: 'us',
    language: 'en',
  },
  {
    country: 'kr',
    language: 'ko',
  },
  {
    country: 'cn',
    language: 'zh',
  },
];
