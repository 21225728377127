import styled from '@emotion/styled';

type Props = {
  background?: string;
  children: React.ReactNode;
};

function PageContainer({ background, children }: Props) {
  return <Layout background={background}>{children}</Layout>;
}

export default PageContainer;

const Layout = styled.div<Props>`
  max-width: 767px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  ${({ background }) => background && `background: ${background}`};
`;
