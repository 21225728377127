import { ReactNode } from 'react';
import { Outlet } from 'react-router';

type LayoutProps = {
  children?: ReactNode;
};

function Layout({ children }: LayoutProps) {
  return <>{children || <Outlet />}</>;
}

export default Layout;
