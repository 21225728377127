import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enHelpCenter from './en/HelpCenter.json';
import zhHelpCenter from './zh/HelpCenter.json';
import enHeader from './en/Header.json';
import koHeader from './ko/Header.json';
import zhHeader from './zh/Header.json';
import enSunnei from './en/Sunnei.json';
import koSunnei from './ko/Sunnei.json';
import zhSunnei from './zh/Sunnei.json';
import enFall from './en/Fall.json';
import koFall from './ko/Fall.json';
import zhFall from './zh/Fall.json';
import enSecondskin from './en/Secondskin.json';
import koSecondskin from './ko/Secondskin.json';
import zhSecondskin from './zh/Secondskin.json';
import enShowroom from './en/Showroom.json';
import koShowroom from './ko/Showroom.json';
import zhShowroom from './zh/Showroom.json';
import enClassic from './en/Classic.json';
import koClassic from './ko/Classic.json';
import zhClassic from './zh/Classic.json';
import enGentleMonster from './en/GentleMonster.json';
import koGentleMonster from './ko/GentleMonster.json';
import zhGentleMonster from './zh/GentleMonster.json';
import enPickYourColor from './en/PickYourColor.json';
import koPickYourColor from './ko/PickYourColor.json';
import zhPickYourColor from './zh/PickYourColor.json';
import enArmani from './en/Armani.json';
import koArmani from './ko/Armani.json';
import zhArmani from './zh/Armani.json';
import enRabanne from './en/Rabanne.json';
import koRabanne from './ko/Rabanne.json';
import zhRabanne from './zh/Rabanne.json';
import enKnitSet from './en/KnitSet.json';
import koKnitSet from './ko/KnitSet.json';
import zhKnitSet from './zh/KnitSet.json';
import enHalloween from './en/Halloween.json';
import koHalloween from './ko/Halloween.json';
import zhHalloween from './zh/Halloween.json';

const resources = {
  en: {
    helpCenter: enHelpCenter,
    header: enHeader,
    sunnei: enSunnei,
    fall: enFall,
    secondskin: enSecondskin,
    showroom: enShowroom,
    classic: enClassic,
    gentleMonster: enGentleMonster,
    pickYourColor: enPickYourColor,
    armani: enArmani,
    rabanne: enRabanne,
    knitSet: enKnitSet,
    halloween: enHalloween,
  },
  ko: {
    header: koHeader,
    sunnei: koSunnei,
    fall: koFall,
    secondskin: koSecondskin,
    showroom: koShowroom,
    classic: koClassic,
    gentleMonster: koGentleMonster,
    pickYourColor: koPickYourColor,
    armani: koArmani,
    rabanne: koRabanne,
    knitSet: koKnitSet,
    halloween: koHalloween,
  },
  zh: {
    helpCenter: zhHelpCenter,
    header: zhHeader,
    sunnei: zhSunnei,
    fall: zhFall,
    secondskin: zhSecondskin,
    showroom: zhShowroom,
    classic: zhClassic,
    gentleMonster: zhGentleMonster,
    pickYourColor: zhPickYourColor,
    armani: zhArmani,
    rabanne: zhRabanne,
    knitSet: zhKnitSet,
    halloween: zhHalloween,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
